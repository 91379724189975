<template>
  <div>
    <inputTextarea
      :data="data.text"
      :label="$t('design.info.description.text')"
      :callback="
        function(value) {
          setValue(value);
        }
      "
      :error="false"
      :styling="{ input: 'width: 100%; max-width: 100%; display: block' }"
    />
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      data: this.getValue()
    };
  },
  computed: {},
  mounted() {},
  methods: {
    setValue(ret) {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      data.info.text = ret.split("\n").join("<br>");
      this.$store.commit("business/prefetch", data);
    },
    getValue() {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));

      if (typeof data.info === "undefined") {
        return {
          text: ""
        };
      }
      data.info.text = data.info.text.split("<br>").join("\n");
      return data.info;
    }
  }
};
</script>
<style lang="sass" scoped></style>
